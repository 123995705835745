import { Button, Col, Descriptions, Form, Input, Row, Select, Spin, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { ENABLE, REBATE_PERIOD } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { convertedToPercentage01 as $g, convertedToPercentage02 as $p2n, toFormatNumber as $f, verify } from 'utils/common';
import * as common from 'utils/common';
import {  } from 'utils/common';

// 會員返水設定

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = Form.useForm();
  const [list, setList] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { data, isValidating } = $get({ url: 'api/memberoperationparams/level/list' });
  useEffect(() => {
    if (data?.Data) setList(data.Data);
  }, [data]);
  useEffect(() => {
    init();
  }, [list]);

  const init = (validate = false) => {
    if (data) {
      form.setFieldsValue({
        SettleCycle: Number(data.SettleCycle || 0),
        Status: Number(data.Status),
        Minimum: data.Minimum,
        Maximum: data.Maximum,
        ValidbetMultiple: data.ValidbetMultiple
      })
    }
    if (data?.Data) {
      const field: any = {};
      data.Data.map((item: any, i: number) => {
        field[`CockfightCommissionRate-${i}`] = $g(item.CockfightCommissionRate);
        field[`EGamesCommissionRate-${i}`] = $g(item.EGamesCommissionRate);
        field[`ESportsCommissionRate-${i}`] = $g(item.ESportsCommissionRate);
        field[`FishingCommissionRate-${i}`] = $g(item.FishingCommissionRate);
        field[`LiveCommissionRate-${i}`] = $g(item.LiveCommissionRate);
        field[`LotteryCommissionRate-${i}`] = $g(item.LotteryCommissionRate);
        field[`PokerCommissionRate-${i}`] = $g(item.PokerCommissionRate);
        field[`SlotCommissionRate-${i}`] = $g(item.SlotCommissionRate);
        field[`SportsCommissionRate-${i}`] = $g(item.SportsCommissionRate);
      })
      form.setFieldsValue(field);
    }
    if (validate) form.validateFields();
  };

  const save = (formData: any) => {
    const Settings: any = [];
    list.map((item: any, i: number) => {
      const record: any = {};
      record[`Id`] = item.Id;
      record[`CockfightCommissionRate`] = parseFloat($p2n(formData[`CockfightCommissionRate-${i}`]));
      record[`EGamesCommissionRate`] = parseFloat($p2n(formData[`EGamesCommissionRate-${i}`]));
      record[`ESportsCommissionRate`] = parseFloat($p2n(formData[`ESportsCommissionRate-${i}`]));
      record[`FishingCommissionRate`] = parseFloat($p2n(formData[`FishingCommissionRate-${i}`]));
      record[`LiveCommissionRate`] = parseFloat($p2n(formData[`LiveCommissionRate-${i}`]));
      record[`LotteryCommissionRate`] = parseFloat($p2n(formData[`LotteryCommissionRate-${i}`]));
      record[`PokerCommissionRate`] = parseFloat($p2n(formData[`PokerCommissionRate-${i}`]));
      record[`SlotCommissionRate`] = parseFloat($p2n(formData[`SlotCommissionRate-${i}`]));
      record[`SportsCommissionRate`] = parseFloat($p2n(formData[`SportsCommissionRate-${i}`]));

      Settings.push(record);
    })

    $post({
      url: 'api/memberoperationparams/level/update',
      send: {
        Status: formData.Status.toString(),
        MinCommissionAmount: parseFloat(formData.Minimum),
        MaxCommissionAmount: parseFloat(formData.Maximum),
        CommissionValidBetMultiple: Number(formData.ValidbetMultiple),
        Settings
      },
      success: () => {
        message.success(i18n.t('saveSuccess'));
        setIsEdit(false);
      }
    })
  };

  // 返水驗證: 限制上下層
  const validator: any = async (d: any, val: number, i: number, rateName: string) => {
    const _val: number = Number(val);
    const upperInputValue: number = Number(form.getFieldValue(`${rateName}-${i - 1}`));
    const downerInputValue: number = Number(form.getFieldValue(`${rateName}-${i + 1}`));
    const min: number = 0;
    const max: number = 100;

    // 需大於等於min
    if (_val < min) return Promise.reject(() => 
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')}${common.toFormatNumber(min, 0)}`}</p>);
    // 需小於等於max
    else if (_val > max) return Promise.reject(() => 
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')}${common.toFormatNumber(max, 0)}`}</p>);
    // 需大於等於上層
    else if (_val < upperInputValue && (upperInputValue && upperInputValue !== 0) && upperInputValue < max) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')}${common.toFormatNumber(upperInputValue, 2)}`}</p>);
    // 需小於等於下層
    else if (_val > downerInputValue && (downerInputValue && downerInputValue !== 0) && downerInputValue > min) return Promise.reject(() => 
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')}${common.toFormatNumber(downerInputValue, 2)}`}</p>);

    else return Promise.resolve();
  }

  const validateOther: any = async (i: number, rateName: string) => {
    form.validateFields([`${rateName}-${i - 1}`, `${rateName}-${i + 1}`]);
  }

  const disable = !$p('30602');

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form form={form} component={false} onFinish={save}>
          {/* 確認取消 */}
          {!disable && (
            <Row gutter={[16, 16]} className="pb-1" justify={'end'}>
              {!isEdit 
              ? <Col><Button onClick={() => { setIsEdit(true); }} type="primary">{i18n.t('edit')}</Button></Col>
              : <>
                <Col><Button onClick={() => { setIsEdit(false); init(true); }}>{i18n.t('cancel')}</Button></Col>
                <Col><Button onClick={form.submit} type="primary">{i18n.t('confirm')}</Button></Col>
              </>}
            </Row>
          )}
          <Spin spinning={isValidating}>
          
          {/* 返水週期 */}
          <Row gutter={[16, 16]} className="pb-1">
            <Col span={24}> 
              <Descriptions layout="vertical" column={6} bordered size="small">
                {/* 返水週期 */}
                <Descriptions.Item style={{ width: '16.6%' }} label={<><span className="require">*</span>{i18n.t('rebatePeriod')}</>}>
                  <Form.Item name="SettleCycle">
                    <Select
                      style={{ width: '100%' }}
                      disabled
                      options={common.enumToOptions(REBATE_PERIOD)}
                    />
                  </Form.Item>
                </Descriptions.Item>
                {/* 狀態 */}
                <Descriptions.Item style={{ width: '16.6%' }} label={<><span className="require">*</span>{i18n.t('status')}</>}>
                  <Form.Item name="Status">
                    <Select
                      style={{ width: '100%' }}
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      disabled={disable || !isEdit}
                      options={common.enumToOptions(ENABLE)}
                      // onChange={val => setStatusDisabled(val === ENABLE.disabled)}
                    />
                  </Form.Item>
                </Descriptions.Item>
                {/* 結算時間 */}
                <Descriptions.Item style={{ width: '16.6%' }} label={<><span className="require">*</span>{i18n.t('settlementTime')}</>}>
                  {dayjs('2024-01-10 00:00:00').tz(window?.serverZone).format('HH:mm')}
                </Descriptions.Item>
                {/* 返水下限 */}
                <Descriptions.Item style={{ width: '16.6%' }} label={<><span className="require">*</span>{i18n.t('minimumRebate')}</>}>
                  <Form.Item name="Minimum" rules={verify({ 
                    point: 4, min: 0.01, max: 100000000.0000, isShowCompareNumber: true })}>
                    <Input disabled={disable || !isEdit} placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Descriptions.Item>
                {/* 返水上限 */}
                <Descriptions.Item style={{ width: '16.6%' }} label={<><span className="require">*</span>{i18n.t('maximumRebate')}</>}>
                  <Form.Item name="Maximum" rules={verify({ point: 4, min: 1.0000, max: 100000000.0000, isShowCompareNumber: true })}>
                    <Input disabled={disable || !isEdit} placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Descriptions.Item>
                {/* 洗碼倍率 */}
                <Descriptions.Item style={{ width: '16.6%' }} label={<><span className="require">*</span>{i18n.t('rolloverRate')}</>}>
                  <Form.Item name="ValidbetMultiple" rules={verify({ point: 0, min: 0 })}>
                    <Input disabled={disable || !isEdit} placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          {/* 列表 */}
          <Row gutter={[16, 16]} className="pb-1">
            <Col className="w-full">
              <Table
                size='small'
                bordered
                dataSource={list?.map((item: LevelInfoType) => ({ ...item, key: item.Position }))}
                columns={[
                  {
                    dataIndex: 'Name',
                    title: i18n.t('levelName'),
                    width: 150,
                  },
                  {
                    title: `${i18n.t('gameCategory')}-${i18n.t('rebateRatio')}`,
                    width: 200,
                    children: [
                      {
                        dataIndex: 'SlotCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('SLOT')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`SlotCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'SlotCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'SlotCommissionRate')} />
                          </Form.Item>
                        )
                      },
                      {
                        dataIndex: 'LiveCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('LIVE')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`LiveCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'LiveCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'LiveCommissionRate')} />
                          </Form.Item>
                        )
                      }, 
                      {
                        dataIndex: 'SportsCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('SPORTS')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`SportsCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'SportsCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'SportsCommissionRate')} />
                          </Form.Item>
                        )
                      },
                      {
                        dataIndex: 'PokerCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('POKER')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`PokerCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'PokerCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'PokerCommissionRate')} />
                          </Form.Item>
                        )
                      },
                      {
                        dataIndex: 'FishingCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('FISHING')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`FishingCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'FishingCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'FishingCommissionRate')} />
                          </Form.Item>
                        )
                      },
                      {
                        dataIndex: 'LotteryCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('LOTTERY')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`LotteryCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'LotteryCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'LotteryCommissionRate')} />
                          </Form.Item>
                        )
                      },
                      {
                        dataIndex: 'EGamesCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('EGAMES')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`EGamesCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'EGamesCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'EGamesCommissionRate')} />
                          </Form.Item>
                        )
                      },
                      {
                        dataIndex: 'ESportsCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('ESPORTS')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`ESportsCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'ESportsCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'ESportsCommissionRate')} />
                          </Form.Item>
                        )
                      },
                      {
                        dataIndex: 'CockfightCommissionRate',
                        title: <><span className="require">*</span>{i18n.t('COCKFIGHT')}</>,
                        width: 150,
                        render: (_, record: any, i) => (
                          <Form.Item name={`CockfightCommissionRate-${i}`} rules={[
                            ...verify({ point: 2 }), 
                            { validator: (d, val) => validator(d, val, i, 'CockfightCommissionRate') }
                          ]}>
                            <Input placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={disable || !isEdit}
                              onChange={() => validateOther(i, 'CockfightCommissionRate')} />
                          </Form.Item>
                        )
                      },
                    ]
                  }
                ]}
                pagination={false}
              />
            </Col>
          </Row>

          </Spin>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;